import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BlogPost } from '../../types';

const Post: React.FC = () => {
  const { postId } = useParams<{ postId: string }>();
  const [post, setPost] = useState<BlogPost | null>(null);
  const [loading, setLoading] = useState(false);

  const populatePost = async () => {
    const token = null;
    const response = await fetch(`blog/post?id=${postId}`, {
      headers: !token ? {} : { Authorization: `Bearer ${token}` }
    });
    const data = await response.json();
    setPost(data);
    setLoading(false);
  };

  useEffect(() => {
    if (!loading && post == null) {
      setLoading(true);
      populatePost();
    }
  }, []);

  return (
    <>
      {post != null && (
        <>
          <h1>{post.name}</h1>
          <p>{post.text}</p>
        </>
      )}
    </>
  );
};

export default React.memo(Post);
