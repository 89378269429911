import { useEffect, useState } from 'react';
import { User } from '../types';

// TODO, make it so this hook can get the user once and share it
// ReactQuery?
export const useUser = (): User | null => {
  const [user, setUser] = useState<User | null>(null);
  const [gettingUser, setGettingUser] = useState(false);
  useEffect(() => {
    const getUser = async () => {
      if (gettingUser) return;
      setGettingUser(true);
      const response = await fetch('user/current-user');
      if (!response.ok) return;
      const localUser = await response.json();
      setUser(localUser);
    };
    if (!gettingUser) getUser();
  }, []);

  return user;
};
