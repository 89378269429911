import { useRoutes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import Layout from './components/Layout';
import './custom.css';

function App(): JSX.Element {
  const routes = useRoutes(AppRoutes);
  return <Layout>{routes}</Layout>;
}

export default App;
