import React, { useEffect, useState } from 'react';
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table
} from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import { Category } from '../../types';

const Categories: React.FC = () => {
  const [categories, setCategories] = useState<{ categories: Category[]; loading: boolean }>({
    categories: [],
    loading: false
  });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [newCategoryTitle, setNewCategoryTitle] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const populateCategories = async () => {
    const token = null;
    const response = await fetch('blog/categories', {
      headers: !token ? {} : { Authorization: `Bearer ${token}` }
    });
    const data = await response.json();
    setCategories({ categories: data, loading: false });
  };

  useEffect(() => {
    if (!categories.loading && categories.categories.length < 1) {
      setCategories({ loading: true, categories: [] });
      populateCategories();
    }
  }, []);

  const createNew = async () => {
    setSubmitting(true);
    const category: Category = {
      id: uuidv4(),
      name: newCategoryTitle
    };
    await fetch('blog/categories', {
      method: 'POST',
      body: JSON.stringify(category),
      headers: { 'Content-Type': 'application/json' }
    });
    await populateCategories();
    setSubmitting(false);
    setModalIsOpen(false);
  };
  return (
    <>
      <h1>Categories</h1>
      <Button onClick={() => setModalIsOpen(true)}>Create New</Button>
      <Table>
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {categories.categories.map((c, i) => (
            <tr>
              <td>{i}</td>
              <td>{c.name}</td>
              <td>
                <a href={`simple-blog/categories/${c.id}/posts`}>Posts</a>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal isOpen={modalIsOpen}>
        <ModalHeader>New Category</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="categoryName">Category Name</Label>
            <Input
              id="categoryName"
              value={newCategoryTitle}
              onChange={(e) => setNewCategoryTitle(e.target.value)}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button disabled={submitting} onClick={createNew}>
            Submit
          </Button>
          <Button
            onClick={() => {
              setModalIsOpen(false);
              setNewCategoryTitle('');
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default React.memo(Categories);
