import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from '../../hooks/user';
import { ApplicationPaths, QueryParameterNames } from './ApiAuthorizationConstants';

interface AuthorizeRouteProps {
  authenticate?: boolean;
  children: React.ReactNode;
}

const AuthorizeRoute: React.FC<AuthorizeRouteProps> = ({ authenticate, children }) => {
  const kids = <React.Fragment children={children} />;
  const user = useUser();
  if (!authenticate) return kids;
  const link = document.createElement('a');
  link.href = window.location.href.toLowerCase();
  const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
  const redirectUrl = `${ApplicationPaths.Login}?${
    QueryParameterNames.ReturnUrl
  }=${encodeURIComponent(returnUrl)}`;
  if (!user) return <></>;

  return user !== null ? kids : <Navigate replace to={redirectUrl} />;
};

export default React.memo(AuthorizeRoute);
