// import { User, UserManager, WebStorageStateStore } from 'oidc-client';

import { User } from '../../types';

export enum AuthenticationResultStatus {
  Redirect = 'redirect',
  Success = 'success',
  Fail = 'fail'
}

// export const createArguments = (state: any) => {
//   return { useReplaceToNavigate: true, data: state };
// };

// export const error = (message: any) => {
//   return { status: AuthenticationResultStatus.Fail, message };
// };

// export const success = (state: any) => {
//   return { status: AuthenticationResultStatus.Success, state };
// };

// export const redirect = () => {
//   return { status: AuthenticationResultStatus.Redirect };
// };

// export class AuthorizeService {
//   // _callbacks = [];
//   // _nextSubscriptionId = 0;
//   // user: User | null = null;

//   authenticated = false;

//   // By default pop ups are disabled because they don't work properly on Edge.
//   // If you want to enable pop up authentication simply set this flag to false.
//   popUpDisabled = true;

//   // userManager?: UserManager;

//   async isAuthenticated() {
//     const user = await this.getUser();
//     return !!user;
//   }

//   async getUser() {
//     if (this.user && this.user.profile) {
//       return this.user.profile;
//     }

//     await this.ensureUserManagerInitialized();
//     const user = await this.userManager!.getUser();
//     return user && user.profile;
//   }

//   async getAccessToken() {
//     await this.ensureUserManagerInitialized();
//     const user = await this.userManager!.getUser();
//     return user && user.access_token;
//   }

//   // We try to authenticate the user in three different ways:
//   // 1) We try to see if we can authenticate the user silently. This happens
//   //    when the user is already logged in on the IdP and is done using a hidden iframe
//   //    on the client.
//   // 2) We try to authenticate the user using a PopUp Window. This might fail if there is a
//   //    Pop-Up blocker or the user has disabled PopUps.
//   // 3) If the two methods above fail, we redirect the browser to the IdP to perform a traditional
//   //    redirect flow.
//   async signIn(state: any) {
//     await this.ensureUserManagerInitialized();
//     try {
//       const silentUser = await this.userManager!.signinSilent(createArguments(state));
//       this.updateState(silentUser);
//       return success(state);
//     } catch (silentError) {
//       // User might not be authenticated, fallback to popup authentication
//       console.log('Silent authentication error: ', silentError);

//       try {
//         if (this.popUpDisabled) {
//           throw new Error(
//             "Popup disabled. Change 'AuthorizeService.js:AuthorizeService._popupDisabled' to false to enable it."
//           );
//         }

//         const popUpUser = await this.userManager!.signinPopup(createArguments(undefined));
//         this.updateState(popUpUser);
//         return success(state);
//       } catch (popUpError) {
//         // eslint-disable-next-line @typescript-eslint/ban-ts-comment
//         // @ts-ignore
//         if (popUpError.message === 'Popup window closed') {
//           // The user explicitly cancelled the login action by closing an opened popup.
//           return error('The user closed the window.');
//         }
//         console.log('Popup authentication error: ', popUpError);

//         // PopUps might be blocked by the user, fallback to redirect
//         try {
//           await this.userManager!.signinRedirect(createArguments(state));
//           return redirect();
//         } catch (redirectError) {
//           console.log('Redirect authentication error: ', redirectError);
//           return error(redirectError);
//         }
//       }
//     }
//   }

//   async completeSignIn(url: string) {
//     try {
//       await this.ensureUserManagerInitialized();
//       const user = await this.userManager!.signinCallback(url);
//       this.updateState(user);
//       return success(user && user.state);
//     } catch (ex) {
//       console.log('There was an error signing in: ', ex);
//       return error('There was an error signing in.');
//     }
//   }

//   // We try to sign out the user in two different ways:
//   // 1) We try to do a sign-out using a PopUp Window. This might fail if there is a
//   //    Pop-Up blocker or the user has disabled PopUps.
//   // 2) If the method above fails, we redirect the browser to the IdP to perform a traditional
//   //    post logout redirect flow.
//   async signOut(state: any) {
//     await this.ensureUserManagerInitialized();
//     try {
//       if (this.popUpDisabled) {
//         throw new Error(
//           "Popup disabled. Change 'AuthorizeService.js:AuthorizeService._popupDisabled' to false to enable it."
//         );
//       }

//       await this.userManager!.signoutPopup(createArguments(undefined));
//       this.updateState(null);
//       return success(state);
//     } catch (popupSignOutError) {
//       console.log('Popup signout error: ', popupSignOutError);
//       try {
//         await this.userManager!.signoutRedirect(createArguments(state));
//         return redirect();
//       } catch (redirectSignOutError) {
//         console.log('Redirect signout error: ', redirectSignOutError);
//         return error(redirectSignOutError);
//       }
//     }
//   }

//   async completeSignOut(url: string) {
//     await this.ensureUserManagerInitialized();
//     try {
//       const response = await this.userManager!.signoutCallback(url);
//       this.updateState(null);
//       // eslint-disable-next-line @typescript-eslint/ban-ts-comment
//       // @ts-ignore
//       return this.success(response && response.data);
//     } catch (ex) {
//       console.log(`There was an error trying to log out '${ex}'.`);
//       return error(ex);
//     }
//   }

//   updateState(user: User | null) {
//     this.user = user;
//     this.authenticated = !!this.user;
//     // this.notifySubscribers();
//   }

//   // subscribe(callback) {
//   //   this._callbacks.push({ callback, subscription: this._nextSubscriptionId++ });
//   //   return this._nextSubscriptionId - 1;
//   // }

//   // unsubscribe(subscriptionId) {
//   //   const subscriptionIndex = this._callbacks
//   //     .map((element, index) =>
//   //       element.subscription === subscriptionId ? { found: true, index } : { found: false }
//   //     )
//   //     .filter((element) => element.found === true);
//   //   if (subscriptionIndex.length !== 1) {
//   //     throw new Error(`Found an invalid number of subscriptions ${subscriptionIndex.length}`);
//   //   }

//   //   this._callbacks.splice(subscriptionIndex[0].index, 1);
//   // }

//   // notifySubscribers() {
//   //   for (let i = 0; i < this._callbacks.length; i++) {
//   //     const callback = this._callbacks[i].callback;
//   //     callback();
//   //   }
//   // }

//   async ensureUserManagerInitialized() {
//     if (this.userManager !== undefined) {
//       return;
//     }

//     const response = await fetch(ApplicationPaths.ApiAuthorizationClientConfigurationUrl);
//     if (!response.ok) {
//       throw new Error(`Could not load settings for '${ApplicationName}'`);
//     }

//     const settings = await response.json();
//     settings.automaticSilentRenew = true;
//     settings.includeIdTokenInSilentRenew = true;
//     settings.userStore = new WebStorageStateStore({
//       prefix: ApplicationName
//     });

//     this.userManager = new UserManager(settings);

//     this.userManager.events.addUserSignedOut(async () => {
//       await this.userManager!.removeUser();
//       this.updateState(null);
//     });
//   }
// }

class AuthorizeService {
  user: User | null = null;
  initialized: boolean = false;
  authenticated: boolean = false;

  async signIn(userName: string, password: string): Promise<AuthenticationResultStatus> {
    const form = new FormData();
    form.append('userName', userName);
    form.append('password', password);
    const response = await fetch('user/Login', {
      method: 'POST',
      body: form
    });
    if (!response.ok) return AuthenticationResultStatus.Fail;
    const user: User = await response.json();
    sessionStorage.setItem('userName', user.userName);
    sessionStorage.setItem('userId', user.id);
    return AuthenticationResultStatus.Success;
  }

  async signOut(): Promise<AuthenticationResultStatus> {
    try {
      const response = await fetch('user/Logout', {
        method: 'POST'
      });
      if (!response.ok) return AuthenticationResultStatus.Fail;
      sessionStorage.removeItem('userName');
      sessionStorage.removeItem('userId');
      return AuthenticationResultStatus.Success;
    } catch (e) {
      console.log(e);
      return AuthenticationResultStatus.Fail;
    }
  }

  isAuthenticated(): boolean {
    const userName = sessionStorage.getItem('userName');
    return userName !== null;
  }

  async getUser(): Promise<User | null> {
    if (this.user) return this.user;
    if (this.initialized) return null;
    this.initialized = true;
    const response = await fetch('user/current-user');
    if (!response.ok) return null;
    this.user = await response.json();
    this.authenticated = true;
    return this.user;
  }
}

const authService = new AuthorizeService();

export default authService;
