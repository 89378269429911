import React, { useState } from 'react';
import { ApplicationPaths, LoginActions, QueryParameterNames } from './ApiAuthorizationConstants';
import authService, { AuthenticationResultStatus } from './AuthorizeService';

// THIS IS NOT THE REAL LOGIN PAGE! It could be eventually, but your changes will not be reflected here!

// The main responsibility of this component is to handle the user's login process.
// This is the starting point for the login process. Any component that needs to authenticate
// a user can simply perform a redirect to this component with a returnUrl query parameter and
// let the component perform the login and return back to the return url.
interface LoginProps {
  action: LoginActions;
  message?: string;
}

const Login: React.FC<LoginProps> = ({ action, message }) => {
  const [displayMessage, setDisplayMessage] = useState(message);
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');

  const navigateToReturnUrl = (returnUrl: string) => {
    // It's important that we do a replace here so that we remove the callback uri with the
    // fragment containing the tokens from the browser history.
    window.location.replace(returnUrl);
  };

  const getReturnUrl = (state: any) => {
    const params = new URLSearchParams(window.location.search);
    const fromQuery = params.get(QueryParameterNames.ReturnUrl);
    if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
      // This is an extra check to prevent open redirects.
      throw new Error(
        'Invalid return url. The return url needs to have the same origin as the current page.'
      );
    }
    return (state && state.returnUrl) || fromQuery || `${window.location.origin}/`;
  };

  const login = async (returnUrl: string) => {
    const result = await authService.signIn(userName, password);
    switch (result) {
      case AuthenticationResultStatus.Redirect:
        break;
      case AuthenticationResultStatus.Success:
        await navigateToReturnUrl(returnUrl);
        break;
      case AuthenticationResultStatus.Fail:
        setDisplayMessage('Login failed');
        break;
      default:
        throw new Error(`Invalid status result ${result}.`);
    }
  };

  // const processLoginCallback = async () => {
  //   const url = window.location.href;
  //   const result = await authService.completeSignIn(url);
  //   switch (result.status) {
  //     case AuthenticationResultStatus.Redirect:
  //       // There should not be any redirects as the only time completeSignIn finishes
  //       // is when we are doing a redirect sign in flow.
  //       throw new Error('Should not redirect.');
  //     case AuthenticationResultStatus.Success:
  //       // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //       // @ts-ignore
  //       await navigateToReturnUrl(getReturnUrl(result.state));
  //       break;
  //     case AuthenticationResultStatus.Fail:
  //       // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //       // @ts-ignore
  //       setDisplayMessage(result.message);
  //       break;
  //     default:
  //       throw new Error(`Invalid authentication result status '${result.status}'.`);
  //   }
  // };

  const redirectToApiAuthorizationPath = (apiAuthorizationPath: string) => {
    const redirectUrl = `${window.location.origin}/${apiAuthorizationPath}`;
    // It's important that we do a replace here so that when the user hits the back arrow on the
    // browser they get sent back to where it was on the app instead of to an endpoint on this
    // component.
    window.location.replace(redirectUrl);
  };

  const redirectToRegister = () => {
    redirectToApiAuthorizationPath(
      `${ApplicationPaths.IdentityRegisterPath}?${QueryParameterNames.ReturnUrl}=${encodeURI(
        ApplicationPaths.Login
      )}`
    );
  };

  const redirectToLogin = () => {
    redirectToApiAuthorizationPath(`${ApplicationPaths.IdentityLoginPath}`);
  };

  const redirectToProfile = () => {
    redirectToApiAuthorizationPath(ApplicationPaths.IdentityManagePath);
  };

  switch (action) {
    case LoginActions.Login:
      setDisplayMessage('Redirecting to login');
      redirectToLogin();
      break;
    case LoginActions.LoginFailed:
      // eslint-disable-next-line no-case-declarations
      const params = new URLSearchParams(window.location.search);
      // eslint-disable-next-line no-case-declarations
      const error = params.get(QueryParameterNames.Message);
      setDisplayMessage(error ?? undefined);
      break;
    case LoginActions.Profile:
      redirectToProfile();
      break;
    case LoginActions.Register:
      redirectToRegister();
      break;
    case LoginActions.LoginCallback:
    default:
      throw new Error(`Invalid action '${action}'`);
  }

  switch (action) {
    case LoginActions.Login:
    case LoginActions.Profile:
    case LoginActions.Register:
      return <>{displayMessage}</>;
    default:
      return <></>;
  }
};

export default React.memo(Login);
