import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { NavItem, NavLink } from 'reactstrap';
import { useUser } from '../../hooks/user';
import { ApplicationPaths } from './ApiAuthorizationConstants';

const LoginMenu: React.FC = () => {
  const user = useUser();
  const anonymousView = (registerPath: string, loginPath: string) => {
    return (
      <Fragment>
        <NavItem>
          <NavLink tag={Link} className="text-dark" to={registerPath}>
            Register
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={Link} className="text-dark" to={loginPath}>
            Login
          </NavLink>
        </NavItem>
      </Fragment>
    );
  };

  const authenticatedView = (
    userName: string,
    profilePath: string,
    logoutPath: string,
    logoutState: { local: boolean }
  ) => {
    return (
      <Fragment>
        <NavItem>
          <NavLink tag={Link} className="text-dark" to={profilePath}>
            Hello {userName}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink replace tag={Link} className="text-dark" to={logoutPath} state={logoutState}>
            Logout
          </NavLink>
        </NavItem>
      </Fragment>
    );
  };

  if (!user) {
    const registerPath = `${ApplicationPaths.Register}`;
    const loginPath = `${ApplicationPaths.Login}`;
    return anonymousView(registerPath, loginPath);
  }
  const profilePath = `${ApplicationPaths.Profile}`;
  const logoutPath = `${ApplicationPaths.LogOut}`;
  const logoutState = { local: true };
  return authenticatedView(user.userName, profilePath, logoutPath, logoutState);
};

export default React.memo(LoginMenu);
