import React, { useState } from 'react';
import { ApplicationPaths, LogoutActions, QueryParameterNames } from './ApiAuthorizationConstants';
import authService, { AuthenticationResultStatus } from './AuthorizeService';

interface LogoutProps {
  action: LogoutActions;
  message?: string;
}


// THIS IS NOT THE REAL LOGOUT PAGE! It could be eventually, but your changes will not be reflected here!

// The main responsibility of this component is to handle the user's logout process.
// This is the starting point for the logout process, which is usually initiated when a
// user clicks on the logout button on the LoginMenu component.
const Logout: React.FC<LogoutProps> = ({ action, message }) => {
  const [loggingOut, setLoggingOut] = useState(false);

  const [displayMessage, setDisplayMessage] = useState(message);

  const getReturnUrl = (state: any) => {
    const params = new URLSearchParams(window.location.search);
    const fromQuery = params.get(QueryParameterNames.ReturnUrl);
    if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
      // This is an extra check to prevent open redirects.
      throw new Error(
        'Invalid return url. The return url needs to have the same origin as the current page.'
      );
    }
    return (
      (state && state.returnUrl) ||
      fromQuery ||
      `${window.location.origin}${ApplicationPaths.LoggedOut}`
    );
  };

  const navigateToReturnUrl = (returnUrl: string) => {
    return window.location.replace(returnUrl);
  };

  const logout = async (returnUrl: string) => {
    const isAuthenticated = await authService.isAuthenticated();
    if (isAuthenticated) {
      const result = await authService.signOut();
      switch (result) {
        case AuthenticationResultStatus.Redirect:
          break;
        case AuthenticationResultStatus.Success:
          await navigateToReturnUrl(returnUrl);
          break;
        case AuthenticationResultStatus.Fail:
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setDisplayMessage('Failed to Log Out');
          break;
        default:
          throw new Error('Invalid authentication result status.');
      }
    } else {
      setDisplayMessage('You successfully logged out!');
    }
  };

  const redirectToApiAuthorizationPath = (apiAuthorizationPath: string) => {
    const redirectUrl = `${window.location.origin}/${apiAuthorizationPath}`;
    // It's important that we do a replace here so that when the user hits the back arrow on the
    // browser they get sent back to where it was on the app instead of to an endpoint on this
    // component.
    window.location.replace(redirectUrl);
  };

  const redirectToLogout = () => {
    redirectToApiAuthorizationPath(`${ApplicationPaths.IdentityLogoutPath}`);
  };

  switch (action) {
    case LogoutActions.Logout:
      redirectToLogout();
      return <div>Processing logout</div>;
    case LogoutActions.LoggedOut:
      return <div>{displayMessage}</div>;
    default:
      throw new Error(`Invalid action '${action}'`);
  }
};
export default React.memo(Logout);
