import { RouteObject } from 'react-router-dom';
import { ApplicationPaths, LoginActions, LogoutActions } from './ApiAuthorizationConstants';
import Login from './Login';
import Logout from './Logout';

const ApiAuthorizationRoutes: RouteObject[] = [
  {
    path: ApplicationPaths.Login,
    element: <Login action={LoginActions.Login} />
  },
  {
    path: ApplicationPaths.LoginFailed,
    element: <Login action={LoginActions.LoginFailed} />
  },
  {
    path: ApplicationPaths.LoginCallback,
    element: <Login action={LoginActions.LoginCallback} />
  },
  {
    path: ApplicationPaths.Profile,
    element: <Login action={LoginActions.Profile} />
  },
  {
    path: ApplicationPaths.Register,
    element: <Login action={LoginActions.Register} />
  },
  {
    path: ApplicationPaths.LogOut,
    element: <Logout action={LogoutActions.Logout} />
  },
  {
    path: ApplicationPaths.LogOutCallback,
    element: <Logout action={LogoutActions.LogoutCallback} />
  },
  {
    path: ApplicationPaths.LoggedOut,
    element: <Logout action={LogoutActions.LoggedOut} />
  }
];

export default ApiAuthorizationRoutes;
