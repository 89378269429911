import React, { useEffect, useState } from 'react';
import { Forecast } from '../types';

const FetchData: React.FC = () => {
  const [forecastState, setForecastState] = useState<{ forecasts: Forecast[]; loading: boolean }>({
    forecasts: [],
    loading: false
  });

  useEffect(() => {
    const populateWeatherData = async () => {
      const token = null; // await authService.getAccessToken();
      const response = await fetch('weatherforecast', {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      });
      const data = await response.json();
      setForecastState({ forecasts: data, loading: false });
    };
    if (!forecastState.loading && forecastState.forecasts.length < 1) {
      setForecastState({ loading: true, forecasts: [] });
      populateWeatherData();
    }
  }, []);

  const renderForecastsTable = (forecasts: Forecast[]) => {
    return (
      <table className="table table-striped" aria-labelledby="tableLabel">
        <thead>
          <tr>
            <th>Date</th>
            <th>Temp. (C)</th>
            <th>Temp. (F)</th>
            <th>Summary</th>
          </tr>
        </thead>
        <tbody>
          {forecasts.map((forecast) => (
            <tr key={forecast.date.toString()}>
              <td>{forecast.date.toString()}</td>
              <td>{forecast.temperatureC}</td>
              <td>{forecast.temperatureF}</td>
              <td>{forecast.summary}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const contents = forecastState.loading ? (
    <p>
      <em>Loading...</em>
    </p>
  ) : (
    renderForecastsTable(forecastState.forecasts)
  );

  return (
    <div>
      <h1 id="tableLabel">Weather forecast</h1>
      <p>This component demonstrates fetching data from the server.</p>
      {contents}
    </div>
  );
};

export default React.memo(FetchData);
