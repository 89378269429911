import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table
} from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import { BlogPost } from '../../types';

const Posts: React.FC = () => {
  const [posts, setPosts] = useState<{ posts: BlogPost[]; loading: boolean }>({
    posts: [],
    loading: false
  });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [newBlogPostTitle, setNewBlogPostTitle] = useState('');
  const [newBlogPostText, setNewBlogPostText] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const { categoryId } = useParams<{ categoryId: string }>();

  const populatePosts = async () => {
    const token = null;
    const response = await fetch(`blog/category-posts?categoryId=${categoryId}`, {
      headers: !token ? {} : { Authorization: `Bearer ${token}` }
    });
    const data = await response.json();
    setPosts({ posts: data, loading: false });
  };

  useEffect(() => {
    if (!posts.loading && posts.posts.length < 1) {
      setPosts({ loading: true, posts: [] });
      populatePosts();
    }
  }, []);

  const createNew = async () => {
    setSubmitting(true);
    const blogPost: BlogPost = {
      id: uuidv4(),
      name: newBlogPostTitle,
      text: newBlogPostText,
      categoryId: categoryId!
    };
    await fetch('blog/posts', {
      method: 'POST',
      body: JSON.stringify(blogPost),
      headers: { 'Content-Type': 'application/json' }
    });
    await populatePosts();
    setSubmitting(false);
    setModalIsOpen(false);
  };
  return (
    <>
      <h1>Posts</h1>
      <Button onClick={() => setModalIsOpen(true)}>Create New</Button>
      <Table>
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {posts.posts.map((p, i) => (
            <tr>
              <td>{i}</td>
              <td>{p.name}</td>
              <td>
                <a href={`simple-blog/posts/${p.id}`}>View</a>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal isOpen={modalIsOpen}>
        <ModalHeader>New Blog Post</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="blogPostName">Blog Post Name</Label>
            <Input
              id="blogPostName"
              value={newBlogPostTitle}
              onChange={(e) => setNewBlogPostTitle(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="blogPostText">Text</Label>
            <Input
              type="textarea"
              id="blogPostText"
              value={newBlogPostText}
              onChange={(e) => setNewBlogPostText(e.target.value)}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button disabled={submitting} onClick={createNew}>
            Submit
          </Button>
          <Button
            onClick={() => {
              setModalIsOpen(false);
              setNewBlogPostTitle('');
              setNewBlogPostText('');
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default React.memo(Posts);
