import { RouteObject } from 'react-router-dom';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import Categories from './components/blog/Categories';
import Post from './components/blog/Post';
import Posts from './components/blog/Posts';
import Counter from './components/Counter';
import FetchData from './components/FetchData';
import Home from './components/Home';

const AppRoutes: RouteObject[] = [
  {
    index: true,
    element: (
      <AuthorizeRoute>
        <Home />
      </AuthorizeRoute>
    )
  },
  {
    path: '/counter',
    element: (
      <AuthorizeRoute>
        <Counter />
      </AuthorizeRoute>
    )
  },
  {
    path: '/fetch-data',
    element: (
      <AuthorizeRoute authenticate={true}>
        <FetchData />
      </AuthorizeRoute>
    )
  },
  {
    path: '/simple-blog',
    element: (
      <AuthorizeRoute authenticate={true}>
        <Categories />
      </AuthorizeRoute>
    )
  },
  {
    path: 'simple-blog/categories/:categoryId/posts',
    element: (
      <AuthorizeRoute authenticate={true}>
        <Posts />
      </AuthorizeRoute>
    )
  },
  {
    path: 'simple-blog/posts/:postId',
    element: (
      <AuthorizeRoute authenticate={false}>
        <Post />
      </AuthorizeRoute>
    )
  },
  ...ApiAuthorizationRoutes
];

export default AppRoutes;
